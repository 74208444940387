import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
// import './css/custom-bootstrap.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import App from './Routes';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './nav/Navbar';
import Footer from './nav/footer';
import companyLogo from './components/img/Logo.png';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="main">
        {/* <div className='row justify-content-center w-100'>
          <div className='col-3 text-center'>
            <img src={companyLogo} alt="Company Logo" className="nav-logo me-3" />
          </div>
        </div> */}
        <Navigation />
        <div className="content">
          <App />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  </React.StrictMode>
);
