import React, { useRef } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import working from '../components/img/working.jpeg';
import pet from '../components/img/pet.jpg';
import pet2 from '../components/img/pet2.jpg';
import video from '../components/videos/ginny-painting.mp4';
import dolphin from '../components/img/dolphin.jpg';
import video2 from '../components/videos/ginny-painting-2.mp4';
import '../css/home.css';

export default function AboutMe() {

  const videoRef = useRef(null);

  const handleVideoEnded = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };
  return (
    <Container>
      <h2 className="fs-4 mb-3 mainTitle my-3">
        About Ginny Helsen
      </h2>
      <hr className="thicker-hr w-25" />
      <Row>
        <Col xs={12} md={6}>
          <p>
          Ginny Helsen Art / Designs / Gifts
          A Level Art, OND Fine Art BA
          </p>
          <p>
          Seahorse Art & Design is a local Colwyn Bay business run by myself, solo artist Ginny Helsen. I am a freehand artist and illustrator, recognised predominantly for painting diverse subjects, especially Animals, Landscapes and Seascapes. I prefer to avoid artistic confines, refusing to limit myself to a singular subject matter. As such I also provide Pet Portraits, Koi Carp Paintings, statement Furniture pieces and Coasters. In addition, I hold small personalised Art Classes.
          </p>
          <p>
          I am a fairly versatile creative, capable of rendering most subjects through the medium of drawing or painting. But I’m not adverse to art or craft involving many other materials and substrates.
          While I draw considerable inspiration from the wonders of the natural world, particularly the beauty of animals, my interests span a wide spectrum so I embrace the challenge of diversity.
          Recent additions to my body of work include depictions of elemental subjects such as water, optical illusions, urban street houses, celestial space, botanical studies, a cruise ship, human portraits, and mandalas.
          </p>
          <p>
          In terms of style, my artistic expression gravitates toward the realm of realism and illustration, characterized by a preference for bold and vibrant colors. Artists who have inspired me include David Shepherd, Michaelangelo and Salvadore Dali.
          </p>
          <p>
          My commitment to diversity and innovation in the arts underscores my approach to every project. I thrive on the challenge of transcending boundaries and bringing a multitude of subjects to life through my creative lens.
          </p>
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
          <Image src={working} alt="Ginny Painting" className="image mb-3" />
          </Col>
          <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
          <Image src={dolphin} alt="Dolphin Painting" className="image mb-3" />
          </Col>
          <Col xs={12} md={6}>
          <hr className="thicker-hr" />
          <h2 className="fs-4 mb-3 mainTitle">
            What Can I Do For You?
          </h2>
          <p>
            I have formal training and qualifications in Fine Art, but, it is only through many years of practice and experience that I have been able to fully develop my style.
            <br />
            <br />
            I Illustrate, Decorate, Draw, Paint & Teach Art & Crafts.
            <br />
            <br />
            I can paint to order on virtually any surface from Walls to Canvas, Furniture to Ornaments, Paper to Wood, Slate to Stone.
          </p>
          <hr className="thicker-hr" />
          <h2 className="fs-4 mb-3 mainTitle">
            Make a Style Statement
          </h2>
          <p>
            SeahorseArt has a small range of Stunning, Elegant and totally Unique Furniture pieces for sale
            to grace your home. Each piece has been carefully selected for its quality, practicality and style.
            It has then been renovated and hand painted with intricately detailed design.
            These are all beautiful, “one of a kind” conversation pieces, existing nowhere else in the world.
          </p>
        </Col>
      </Row>
      <hr className="thicker-hr" />

      <Row>
        <Col xs={12}>
          <h2 className="fs-4 mb-3 mainTitle">
            Beautiful Unique Artwork for your Home
          </h2>
          <p>
            Have you ever wished for something really special to place on your wall? I have a variety of unique original Paintings For Sale in my Online Gallery. Some are of local views, some surreal and some of animals. If you don’t see anything there, I can always paint or draw something bespoke which is just special to you. Paintings start from £95 making them the perfect special gift or self-indulgence!
            *Price reflects time and materials. All paintings are carefully and thoughtfully created over several hours, even days! The bigger they are and the more detail, the longer they take.
          </p>
        </Col>
      </Row>
        <video
        className='video mb-4'
        src={video2}
        width="100%"
        height="auto"
        autoPlay
        muted
        onEnded={handleVideoEnded}
        ref={videoRef}
      />
      <hr className="thicker-hr" />
      <Row>
        <Col xs={12} md={6}>
          <h2 className="fs-4 mb-3 mainTitle">
            Pet Portraits and Cut Out Painted Animals
          </h2>
          <p>
            We all love our animals, and never forget them even after they’ve gone. I paint your favourite Pets onto Coasters, Hangers, Canvas and even plywood then cut them out! I specialize in “In Loving Memory” paintings for bereaved owners.
            <br />
            Prices from: Coaster £25, Slate Hanger £45, Canvas £95, Cut out £95
          </p>
        </Col>
        <Col xs={12} md={6}>
          <h2 className="fs-4 mb-3 mb-3 mainTitle">
            Slate, Stone and Ceramic
          </h2>
          <p>
            Fabulous Hand Painted Gifts form SeahorseArt. With a variety of Coasters, PotStands, Ornaments, Lazy Susan’s...you never need to be stuck for ideas again!
            I can paint virtually anything on anything and even personalize them for you. My prices won’t break you either!
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
          <Image src={pet2} alt="Pet Portrait" className="image mb-3" />
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
          <Image src={pet} alt="Pet Portrait" className="image mb-3" />
        </Col>
      </Row>

    </Container>
  )
};
