import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBX8JUwitVbSc1vEGDePua3CifFZkTgHFo",
    authDomain: "ginny-helse-art.firebaseapp.com",
    projectId: "ginny-helse-art",
    storageBucket: "ginny-helse-art.appspot.com",
    messagingSenderId: "372423120778",
    appId: "1:372423120778:web:efdfff7f37c371f5d41a73",
    measurementId: "G-MJ6JTZ8WTD"
  };

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);

export const db = getFirestore(app);