import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, Image } from 'react-bootstrap';
import { db } from '../firebase-config';
import { ToastContainer, toast } from 'react-toastify';
import { FaStar } from 'react-icons/fa';

export default function ReviewsPage() {
  const [reviews, setReviews] = useState([]);
  const reviewCollectionRef = collection(db, 'reviews');

  useEffect(() => {
    const fetchReviews = async () => {
      const querySnapshot = await getDocs(reviewCollectionRef);
      const fetchedReviews = querySnapshot.docs.map((doc) => doc.data());
      setReviews(fetchedReviews);
    };

    fetchReviews();
  }, []);

  const renderStars = (rating) => {
    const filledStars = parseInt(rating, 10);

    return Array.from(Array(5)).map((_, index) => (
      <FaStar key={index} color={index < filledStars ? 'gold' : 'gray'} />
    ));
  };

  return (
    <Container>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <h1 className='fs-3 mainTitle my-3'>Reviews</h1>

      {/* <hr />

      <h2 className='fs-3 mainTitle my-3'>Other Reviews</h2> */}
      <hr className='thicker-hr w-50'></hr>

    <div className='row'>
    {reviews.length === 0 ? (
            <p>No reviews available</p>
        ) : (
            <div className='col-auto'>
            {reviews.map((review) => (
                <div key={review.id} className='p-3 mb-3'>
                <h3>{review.name}</h3>
                <p dangerouslySetInnerHTML={{ __html: review.review}}></p>
                <div className="stars">{renderStars(review.rating)}</div>
                <hr className='thicker-hr w-50'></hr>
                <Image src={review.imageUrl} className='img-fluid w-50 artwork-image mx-auto' />
                </div>
            ))}
            </div>
            
        )}
    </div>


    </Container>
  );
}
