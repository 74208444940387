import { addDoc, collection } from "firebase/firestore";
import { Form, Button } from "react-bootstrap"; // Import Button from react-bootstrap
import { useState } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { db, storage } from '../../firebase-config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';

const videoCollectionRef = collection(db, "videos");

export default function VideoForm(){
    
  const [newVideoText, setNewVideoText] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [videoUpload, setVideoUpload] = useState(null);
  const [videoName, setVideoName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleVideoSubmit = async (e) => {
    e.preventDefault();

    if (loading) {
      return; // Do nothing if already in a loading state
    }

    setLoading(true);

    const upload = async () => {
      try {
        if (videoUpload != null) {
          const videoRef = ref(storage, `videos/${videoName}`);
          const uploadTask = uploadBytesResumable(videoRef, videoUpload);
          const snapshot = await uploadTask;

          if (snapshot.state === "success") {
            const downloadUrl = await getDownloadURL(videoRef);

            await addDoc(videoCollectionRef, {
              videoDescription: newVideoText,
              name: newTitle,
              videoName: videoName,
              videoUrl: downloadUrl,
            });
            toast.success("Video Uploaded");
          }
        }
      } catch (error) {
        console.error("Error uploading artwork:", error);
        toast.error("Error uploading artwork. Please try again.");
      } finally {
        setLoading(false); // Ensure loading is turned off
      }
    };

    await upload();

    toast.success("Video Successfully Submitted");
    setNewTitle("");
    setVideoName("");
    setVideoUpload("");
    setNewVideoText("");
  };

  return (
    <>
      <h1 className="fs-3 mainTitle my-3">Add Video</h1>
      <hr className="w-25 thicker-hr"></hr>
      <Form onSubmit={handleVideoSubmit} style={{ marginBottom: 80 }}>
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            type="text"
            name="title"
            placeholder="Enter a title"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Video Text</Form.Label>
          <ReactQuill
            min="8"
            max="200"
            name="description"
            value={newVideoText}
            onChange={setNewVideoText}
            placeholder="Enter Video Text"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            required
            type="file"
            onChange={(event) => {
              setVideoUpload(
                event.target.files[0],
                setVideoName(event.target.files[0].name + v4())
              );
            }}
          />
        </Form.Group>

        <Button
          variant="success"
          type="submit"
          className="mx-auto"
          style={{ backgroundColor: "#008080" }}
        >
          Submit Video
        </Button>
      </Form>
    </>
  );
}