import { useEffect, useState } from 'react';
import { Link, useParams, Navigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Image, Button, Col, Row } from 'react-bootstrap';
import '../css/artwork.css';
import { FaEtsy, FaStar } from 'react-icons/fa';
import ErrorPage from './ErrorPage';

export default function ArtworkDetails() {
  const { id } = useParams();
  
  const [artwork, setArtwork] = useState(null);
  const [reviews, setReviews] = useState([]);
  const reviewCollectionRef = collection(db, 'reviews');

  useEffect(() => {
    console.log(id==='0');
    var fetchData = null;
    fetchData = async () => {
      const artCollectionRef = collection(db, 'art');
      const q = query(artCollectionRef, where('id', '==', parseInt(id)));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];
        setArtwork(docSnapshot.data());
      } else {
        setArtwork(null);
      }
    }


    const fetchReviews = async () => {
      const querySnapshot = await getDocs(reviewCollectionRef);
      const fetchedReviews = querySnapshot.docs.map((doc) => doc.data());
      setReviews(fetchedReviews);
    };

    fetchData();
    fetchReviews();
  }, [id]);

  if (id === '0') {
    return <Navigate to="/paintings" />;
  }

  if (!artwork) {
    return <ErrorPage />;

  }

  const renderStars = (rating) => {
    const filledStars = parseInt(rating, 10);

    return Array.from(Array(5)).map((_, index) => (
      <FaStar key={index} color={index < filledStars ? 'gold' : 'gray'} />
    ));
  };

  return (
    <div className="artwork-details p-0">
      <div className='p-3'>
        <Row>
          <Col md={6} className='mb-3'>
            <Row>
              <Image src={artwork.imageUrl} className='img-fluid artwork-image mx-auto' />
            </Row>
            <Row className="d-none d-md-flex">
            {reviews.length === 0 ? (
                  <p>No reviews available</p>
              ) : (
                  <div className='px-5 py-2'>
                  {reviews.map((review) => (
                      <div key={review.id} className='p-3 mb-3'>
                      <h3>Reviews</h3>
                      <hr className='thicker-hr'></hr>
                      <h4>{review.name}</h4>
                      <p dangerouslySetInnerHTML={{ __html: review.review}}></p>
                      <p>Rating: {review.rating}</p>
                      <div className="stars">{renderStars(review.rating)}</div>
                      </div>
                  ))}
                  </div>
              )}
            </Row>
          </Col>
          <Col md={6}>
            <div>
              <h5 className="fs-1 fw-bold mb-4">{artwork.title}</h5>
              <p className='marginb'>
                <span className={`fs-3 fw-bold ${artwork.availability === 'onRequest' ? 'text-secondary' : ''}`}>
                  £{artwork.price}
                </span>
                {artwork.availability === 'onRequest' ? (
                  // <<Badge bg="danger" className="ms-2 fs-3">Sold</Badge>>
                  <span className='fs-4 text-danger ms-4 text-uppercase fw-bold'>Sold</span>
                ) : null}
              </p>
              <p className="artwork-shipping mb-4 text-secondary">*Shipping Prices May Vary</p>
              {artwork.availability === 'available' && ( 
              <Button
                as={Link}
                to={`/contact-me/${artwork.id}`}
                className="btn btn-primary my-4 mb-5 buy px-5 py-2 text-white artwork-shipping"
                style={{ backgroundColor: '#008080', border: 'none', color: '#FFFFFF' }}
              >
                  <span className='buttonText fs-5 fw-bolder'>Buy Now</span>
              </Button>
            )}
            
            {artwork.availability === 'onRequest' && (
              <>
                <p className="artwork-shipping fs-6">This item was sold but I can take requests if you want one just like it! Just click the button below.</p>
                <Button
                  as={Link}
                  to={`/contact-me/${artwork.id}`}
                  className="btn btn-primary my-4 buy px-5 py-2 text-white artwork-shipping"
                  style={{ backgroundColor: '#008080', border: 'none', color: '#FFFFFF' }}
                >
                  <span className='buttonText fs-5 fw-bolder'>Request</span>
                </Button>
              </>
            )}
            {artwork.link != null && artwork.link !== '' && (
              <Button className='btn btn-primary artwork-shipping etsy text-white my-2 py-2 px-3 ms-3' href={artwork.link} target="_blank">
                <FaEtsy />
              </Button>
              )}           

            {/* {artwork.availability === 'sold' && (
              <>
              <p className="artwork-shipping">This item was sold but I can take requests if you want one just like it! Just click the button below.</p>
              <Button
                disabled
                className="btn btn-primary buy my-2 px-5 py-2 text-white artwork-shipping"
                style={{ backgroundColor: '#008080', border: 'none', color: '#FFFFFF' }}
              >
                Sold (Painted on Request)
              </Button>
              </>
            )} */}
              <div>
                <h6 className="artwork-price fs-5 my-4">Description</h6>
                <div className="artwork-description" dangerouslySetInnerHTML={{ __html: artwork.description }} />
                <p className='ref'>Artwork Reference: {artwork.id}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
