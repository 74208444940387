import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaEtsy, FaFacebook, FaInstagram, FaPinterest } from 'react-icons/fa';
import companyLogo from '../components/img/Logo.png';
import designerLogo from '../components/img/Company Logo.png';
import '../css/footer.css';

function Footer() {
  return (
    <footer className="text-md-center py-4 mt-5 border-top border-dark text-center">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <div>
              <h5>Contact Information</h5>
              <p>72 Dinerth Rd, Rhos, Colwyn Bay LL284EY</p>
              <p>Email: Ginnyhelsen@gmail.com</p>
              <p>Phone: 07814901965</p>
            </div>
          </Col>
          <Col xs={12} md={12} className="text-md-center">
              <a href="https://www.facebook.com/Seahorseartanddesign" target="_blank" rel="noreferrer" className="me-3">
                <FaFacebook size={24} />
              </a>
              <a href="https://www.instagram.com/seahorseartgifts/" target="_blank" rel="noreferrer" className="me-3">
                <FaInstagram size={24} />
              </a>
              <a href="http://ginnyhelsen.etsy.com/" target="_blank" rel="noreferrer" className="">
                <FaEtsy size={24}/>
              </a>
          </Col>
          <Col xs={12} md={12} className="text-md-center">
          <div className='mt-2'></div>
            <a href="https://clever-robin.com/solutions/" target="_blank" rel="noreferrer">
              <img src={designerLogo} alt="Designer Logo" className="designer-logo mb-3" />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
