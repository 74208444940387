import 'bootstrap/dist/css/bootstrap.min.css';
import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import '../css/videos.css';
import { Container } from 'react-bootstrap';
import { db } from '../firebase-config';

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const videoCollectionRef = collection(db, 'videos');

  useEffect(() => {
    const fetchedVideos = async () => {
      const querySnapshot = await getDocs(videoCollectionRef);
      const fetchedVideos = querySnapshot.docs.map((doc) => doc.data());
      setVideos(fetchedVideos);
    };

    fetchedVideos();
  }, []);

  return (
    <Container>
      <h1 className='fs-3 mainTitle my-3'>Videos</h1>
      <hr className='thicker-hr w-100'></hr>

      <div className='row'>
  {videos.length === 0 ? (
    <p>No Videos available</p>
  ) : (
    <div className='col-12'>
      {videos.map((video) => (
        <div key={video.id} className='p-3 mb-3'>
          {video.name ? <h3>{video.name}</h3> : null}
          {video.videoDescription ? (
            <p dangerouslySetInnerHTML={{ __html: video.videoDescription }} />
          ) : null}                
          <hr className='thicker-hr w-100' />
          <div className="ratio ratio-16x9"> {/* This ensures a 16:9 aspect ratio */}
            <iframe
              className="responsive-video"
              src={video.videoUrl}
              title={video.name}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ))}
    </div>
  )}
</div>


    </Container>
  );
}

export default Videos;
