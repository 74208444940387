import { Routes, Route, useNavigate} from "react-router-dom";
import Home from "./pages/Home";
import AboutMe from "./pages/AboutMe";
import ContactMe from "./pages/ContactMe";
import Paintings from "./pages/Paintings";
import AdminLogin from "./pages/admin/AdminLogin";
import ArtworkDetails from "./pages/ArtworkDetails";
import ReviewsPage from "./pages/Review";
import LessonsAndWorkshops from "./pages/LessonsAndWorkshops";
import Videos from "./pages/Videos";


const App = () => {
  const navigate = useNavigate();
 return (
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/about-me" element={<AboutMe />} />
        <Route path="/contact-me/:id" element={<ContactMe />} />
        <Route path="/paintings" element={<Paintings category="Paintings"/>} />
        <Route path="/admin/login" element={<AdminLogin/>}/>
        <Route path="/review" element={<ReviewsPage/>}/>
        <Route path="/lessons-and-workshops" element={<LessonsAndWorkshops/>}/>
        <Route path="/artwork/:id" element={<ArtworkDetails />} />
        <Route path="/videos" element={<Videos/>}/>
        <Route
        path="*"
        element={
          () => {
            navigate("https://ginny-helse-art.web.app/");
            return null;
          }
        }
      />
      </Routes>
 );
};

export default App;