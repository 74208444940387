import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import rhyl from '../components/img/LessonsAndWorkshops/Rhyl.jpeg';
import kidsClub from '../components/img/LessonsAndWorkshops/KidsClub.jpeg';
import artAndSoul from '../components/img/LessonsAndWorkshops/ArtAndSoul.jpeg';
import workshop1 from '../components/img/LessonsAndWorkshops/Workshop1.jpeg';
import workshop2 from '../components/img/LessonsAndWorkshops/Workshop2.jpeg';
import workshop3 from '../components/img/LessonsAndWorkshops/Workshop3.jpeg';
import workshop4 from '../components/img/LessonsAndWorkshops/Workshop4.jpeg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const ArtLessonsWorkshops = () => {
  return (
    <div className="container my-5">
      <div className="text-center">
        <h1 className="display-4 mb-4">Art Lessons and Workshops</h1>
        <p className="lead text-muted">Over the years I have undertaken a number of different types of workshops for both children and adults.</p>
      </div>

      {/* Section 1: Full-width image */}
      <div className="mb-5">
        <img src={kidsClub} alt="Crafts at Greenwood Park" className="img-fluid rounded shadow" />
        <h2 className="mt-4">Crafts at Greenwood Park in Caernarfon</h2>
        <p>Join us for creative and fun-filled craft workshops suitable for all ages.</p>
      </div>

      {/* Section 2: Two-column layout */}
      <div className="row mb-5">
        <div className="col-md-6">
          <img src={workshop2} alt="Portrait and Flower Painting" className="img-fluid rounded shadow mb-3" />
          <h3>Portrait and Flower Painting in Prestatyn</h3>
          <p>Learn to draw and paint beautiful portraits and flowers in our adult workshops.</p>
        </div>
        <div className="col-md-6">
          <img src={rhyl} alt="EMI Unit Drawing and Painting" className="img-fluid rounded shadow mb-3" />
          <h3>Drawing and Painting with Dementia Residents in Rhyl</h3>
          <p>Experience the joy of art with our specialized workshops for dementia residents.</p>
        </div>
      </div>

      {/* Section 3: Full-width image */}
      <div className="mb-5">
        <img src={artAndSoul} alt="Art & Soul Workshop" className="img-fluid rounded shadow" />
        <h2 className="mt-4">Landscape Art Workshops at Art & Soul in Colwyn Bay</h2>
        <p>Explore the beauty of landscapes through our focused art workshops.</p>
      </div>

      {/* Section 4: Two-column layout */}
      <div className="row mb-5">
        <div className="col-md-6">
          <img src={workshop4} alt="One-on-One Lessons" className="img-fluid rounded shadow mb-3" />
          <h3>One-on-One Art Lessons</h3>
          <p>Personalized attention for developing your unique artistic style.</p>
        </div>
        <div className="col-md-6">
          <img src={workshop3} alt="Creative Art Workshops" className="img-fluid rounded shadow mb-3" />
          <h3>Creative Art Workshops</h3>
          <p>Engage in creative expression through various art mediums.</p>
        </div>
      </div>

      {/* Section 5: Full-width image */}
      <div className="mb-5">
        <img src={workshop1} alt="Community Art Projects" className="img-fluid rounded shadow" />
        <h2 className="mt-4">Community Art Projects</h2>
        <p>Collaborate with others on meaningful community-based art projects.</p>
      </div>
      <div className='row my-4'>
        <Button
          as={Link}
          to={`/contact-me/0`}
          className="btn btn-primary my-4 buy px-5 py-2 text-white artwork-shipping"
          style={{ backgroundColor: '#008080', border: 'none', color: '#FFFFFF' }}
        >
          <span className='buttonText fs-5 fw-bolder'>Contact Me</span>
        </Button>
      </div>
    </div>
  );
}

export default ArtLessonsWorkshops;
