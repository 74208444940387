import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../css/home.css';

import hopeGloryImage from '../components/img/hope&glory.jpg';
import koiImage from '../components/img/koi.jpeg';
import koiImage3 from '../components/img/koi3.jpeg';
import home1 from '../components/img/HomePage/Home1.jpeg'
import home2 from '../components/img/HomePage/Home2.jpeg'
import home3 from '../components/img/HomePage/Home3.jpeg'
import home4 from '../components/img/HomePage/Home4.jpeg'
import home5 from '../components/img/HomePage/Home5.jpeg'
import home6 from '../components/img/HomePage/Home6.jpeg'
import { Container, Image } from 'react-bootstrap';

export default function Home() {

  return (
    
    <div className='flex-container'>
      <h1 className='mainTitle text-center fw-bold mb-3 fs-3'>Original art made by hand</h1>
      <h4 className='text-center mb-4 fs-6'>Home of Illustrations, Paintings & Crafts</h4>
      <div className="carousel-container mx-auto">
        <Carousel
          autoPlay={true}
          interval={3500}
          className='mb-5 rounded-4 mx-auto'
          showStatus={true}
          showThumbs={true}
          infiniteLoop
          style={{ width: '80' }}
        >
          <Image src={hopeGloryImage} alt="Hope and Glory" className="carousel-image" />
          <Image src={koiImage} alt="Koi" className="carousel-image" />
          <Image src={koiImage3} alt="Koi" className="carousel-image" />
          <Image src={home1} alt="Home 1" className="carousel-image" />
          <Image src={home2} alt="Home 2" className="carousel-image" />
          <Image src={home3} alt="Home 3" className="carousel-image" />
          <Image src={home4} alt="Home 4" className="carousel-image" />
          <Image src={home5} alt="Home 5" className="carousel-image" />
          <Image src={home6} alt="Home 6" className="carousel-image" />
        </Carousel>
      </div>
    </div>
  );
}
