import { addDoc, collection } from "firebase/firestore";
import { Form, Button } from "react-bootstrap"; // Import Button from react-bootstrap
import { useState } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { db, storage } from '../../firebase-config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';

const reviewCollectionRef = collection(db, "reviews");

export default function ReviewForm() {
  const [newReview, setNewReview] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newRating, setNewRating] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const [imageName, setImageName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleReviewSubmit = async (e) => {
    e.preventDefault();

    if (loading) {
      return; // Do nothing if already in a loading state
    }

    setLoading(true);

    const upload = async () => {
      try {
        if (imageUpload != null) {
          const imageRef = ref(storage, `artwork/${imageName}`);
          const uploadTask = uploadBytesResumable(imageRef, imageUpload);
          const snapshot = await uploadTask;

          if (snapshot.state === "success") {
            const downloadUrl = await getDownloadURL(imageRef);

            await addDoc(reviewCollectionRef, {
              rating: newRating,
              review: newReview,
              name: newName,
              email: newEmail,
              imageName: imageName,
              imageUrl: downloadUrl,
            });
          }
        }
      } catch (error) {
        console.error("Error uploading artwork:", error);
        toast.error("Error uploading artwork. Please try again.");
      } finally {
        setLoading(false); // Ensure loading is turned off
      }
    };

    await upload();

    toast.success("Review Successfully Submitted");
    setNewRating("");
    setNewEmail("");
    setNewName("");
    setNewReview("");
  };

  return (
    <>
      <h1 className="fs-3 mainTitle my-3">Add Review</h1>
      <hr className="w-25 thicker-hr"></hr>
      <Form onSubmit={handleReviewSubmit} style={{ marginBottom: 80 }}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            required
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            type="text"
            name="name"
            placeholder="Enter name"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            type="email"
            name="email"
            placeholder="Enter email"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Rating</Form.Label>
          <Form.Control
            type="number"
            min="1"
            max="5"
            value={newRating}
            onChange={(e) => setNewRating(e.target.value)}
            placeholder="Rating out of 5"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Review</Form.Label>
          <ReactQuill
            required
            min="8"
            max="200"
            name="description"
            value={newReview}
            onChange={setNewReview}
            placeholder="Enter a Review"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control
            required
            type="file"
            onChange={(event) => {
              setImageUpload(
                event.target.files[0],
                setImageName(event.target.files[0].name + v4())
              );
            }}
          />
        </Form.Group>

        <Button
          variant="success"
          type="submit"
          className="mx-auto"
          style={{ backgroundColor: "#008080" }}
        >
          Submit Review
        </Button>
      </Form>
    </>
  );
}
