import { Container, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';

const ErrorPage = () => {
  return (
    <Container className="d-flex flex-column align-items-center justify-content-center vh-100 text-center">
      <Alert variant="danger">
        <h1>Can't Find Painting</h1>
        <p>The painting you're looking for doesn't seem to exist. Please try again.</p>
      </Alert>
      <Link to={'/paintings'}>
        <button className='btn btn-info'>View Other Paintings</button>
      </Link>
    </Container>
  );
};

export default ErrorPage;
