import { Nav, Container, Navbar, Button } from 'react-bootstrap';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../components/img/Logo.png';

function Navigation() {
  const location = useLocation();
  return (
    <Navbar collapseOnSelect bg="white" expand="lg" className="px-5">
      <Container>
        <Navbar.Brand as={Link} to="/"><img src={logo} alt="Company Logo" className="nav-logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center text-center">
        <Nav className="ms-auto" defaultActiveKey="/">
          <Nav.Item>
            <Nav.Link as={Link} to="/" className={`text-black nav-styles ${location.pathname === '/' ? 'active' : ''}`}>
              Home
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/paintings" className={`text-black nav-styles ${location.pathname.startsWith('/paintings') ? 'active' : ''}`}>
              Art
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/about-me" className={`text-black nav-styles ${location.pathname === '/about-me' ? 'active' : ''}`}>
              About Me
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/lessons-and-workshops" className={`text-black nav-styles ${location.pathname.startsWith('/lessons-and-workshops') ? 'active' : ''}`}>
              Lessons and Workshops
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/videos" className={`text-black nav-styles ${location.pathname.startsWith('/videos') ? 'active' : ''}`}>
              Videos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/contact-me/0" className={`text-black nav-styles ${location.pathname.startsWith('/contact-me') ? 'active' : ''}`}>
              Contact Me
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/review" className={`text-black nav-styles ${location.pathname === '/review' ? 'active' : ''}`}>
              Reviews
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav className="ms-auto">
          <Nav.Link as={Link} to="/admin/login" className="text-black">
          <Button variant="link" type="submit" className="mx-auto" style={{ textDecoration: "none", color: "#008080" }}>
            Log in
          </Button>
          </Nav.Link>
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
