import { useRef, useState } from "react";
import { Form, Button } from "react-bootstrap"; // Import Button from react-bootstrap
import { db, storage } from '../../firebase-config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid'
import ReactQuill from "react-quill";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const reviewCollectionRef = collection(db, 'reviews');

export default function CreateForm() {
  const form = useRef();
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newPrice, setNewPrice] = useState(0);
  const [newAvailability, setNewAvailability] = useState(0);
  const [newCategory, setNewCategory] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const [imageName, setImageName] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);

  const artCollectionRef = collection(db, "art")

  const handleCreate = async (e) => {
    e.preventDefault();

    if (loading) {
      return; // Do nothing if already in a loading state
    }

    setLoading(true);

    const upload = async () => {
      try {
        if (imageUpload != null) {
          const imageRef = ref(storage, `artwork/${imageName}`);
          const uploadTask = uploadBytesResumable(imageRef, imageUpload);
          const snapshot = await uploadTask;

          if (snapshot.state === 'success') {
            const downloadUrl = await getDownloadURL(imageRef);

            const counterDocRef = doc(db, 'counters', 'artwork');
            const counterDocSnapshot = await getDoc(counterDocRef);
            let lastAssignedNumber = 0;

            if (counterDocSnapshot.exists()) {
              lastAssignedNumber = counterDocSnapshot.data().lastAssignedNumber;
            }

            const newAssignedNumber = lastAssignedNumber + 1;
            await setDoc(counterDocRef, { lastAssignedNumber: newAssignedNumber });

            await addDoc(artCollectionRef, {
              id: newAssignedNumber,
              title: newTitle,
              price: newPrice,
              description: newDescription,
              category: newCategory,
              imageName: imageName,
              link: link,
              imageUrl: downloadUrl,
              availability: newAvailability
            });

            toast.success('Artwork successfully uploaded');
            form.current.reset();
            setNewDescription('');
          }
        }
      } catch (error) {
        console.error('Error uploading artwork:', error);
        toast.error('Error uploading artwork. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    await upload();
  };

  return (
    <>
    <h1 className="fs-3 mainTitle my-3">Add Painting</h1>
        <hr className="w-25 thicker-hr"></hr>
      <Form className='mx-auto my-auto' ref={form} onSubmit={handleCreate} style={{marginBottom:80}}>
        <Form.Group className="mb-3">
          <Form.Control required type="file" onChange={(event) => { setImageUpload(event.target.files[0], setImageName(event.target.files[0].name + v4())) }} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Control required type="text" placeholder='Enter a Title' name="title" onChange={(event) => { setNewTitle(event.target.value) }} />
        </Form.Group>

        <Form.Select aria-label="Category" required className='mb-3' name='category' onChange={(event) => { setNewCategory(event.target.value) }}>
          <option>Select a Category</option>
          <option value="Pet Portraits">Pet Portraits</option>
          <option value="Paintings">Paintings</option>
          <option value="Koi Carp">Koi Carp</option>
          <option value="Furniture">Furniture</option>
          <option value="Ornaments">Ornaments</option>
          <option value="Coasters">Coasters</option>
          <option value="Wall Hangers">Wall Hangers</option>
        </Form.Select>

        <Form.Group className="mb-3">
          <ReactQuill
            required
            name="description"
            value={newDescription}
            onChange={setNewDescription}
            placeholder="Enter a Description"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <div className='row'>
            <div className='col-1 my-auto mx-auto'>
              <Form.Control required type="text" value={'£'} disabled name='price' onChange={(event) => { setNewPrice(event.target.value) }} />
            </div>
            <div className='col-11'>
              <Form.Control required type="number" placeholder='Enter a Price' name='price' onChange={(event) => { setNewPrice(event.target.value) }} />
            </div>
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Text style={{color:"gray"}}>(Optional)</Form.Text>
          <Form.Control type="text" placeholder='Enter an Etsy Link' name='link' onChange={(event) => { setLink(event.target.value) }} />
        </Form.Group>

        <Form.Group className="mb-3">
          <div>
            <Form.Check
              inline
              type="radio"
              label="Available"
              name="availability"
              id="available"
              value="available"
              checked={newAvailability === 'available'}
              onChange={(event) => setNewAvailability(event.target.value)}
            />
            <Form.Check
              inline
              type="radio"
              label="Sold"
              name="availability"
              id="onRequest"
              value="onRequest"
              checked={newAvailability === 'onRequest'}
              onChange={(event) => setNewAvailability(event.target.value)}
            />
          </div>
        </Form.Group>

        <Button
        variant="success"
        type="submit"
        className="mx-auto"
        style={{ backgroundColor: '#008080' }}
        disabled={loading} // Disable button when in a loading state
      >
        {loading ? 'Uploading...' : 'Upload Artwork'}
      </Button>
      </Form>
    </>
  );
}
