import React from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { FaTh, FaThLarge } from 'react-icons/fa';
import '../css/paintings.css';
import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Link } from 'react-router-dom';

export default function Paintings() {
  const [isActive, setActive] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('');

  const categories = [
    { id: 'pet-portraits', value: 'Pet Portraits' },
    { id: 'paintings', value: 'Paintings'},
    { id: 'koi-carp', value: 'Koi Carp'},
    { id: 'furniture', value: 'Furniture' },
    { id: 'ornaments', value: 'Ornaments'},
    { id: 'coasters', value: 'Coasters'},
    { id: 'wall-hangers', value: 'Wall Hangers'}
  ];

  const artCollectionRef = collection(db, 'art');

  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [currentCategory, setCurrentCategory] = useState('All Categories');


  function Grid() {
    if (isActive) {
      return (
        <Button onClick={ToggleClass}>
          <FaThLarge size={26} />
        </Button>
      );
    } else {
      return (
        <Button onClick={ToggleClass}>
          <FaTh size={26} />
        </Button>
      );
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      let q = query(artCollectionRef);

      if (filterBy) {
        q = query(artCollectionRef, where('category', '==', filterBy));
      }

      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc) => doc.data());
      setData(fetchedData);
      setCurrentCategory(filterBy || 'All Categories');
    };

    fetchData();
  }, [filterBy]);

  const handleFilter = (filterType) => {
    setFilterBy(filterType);
  };

  const filteredData = data.filter(
    (art) =>
      (art.id && art.id.toString().includes(searchTerm)) ||
      art.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedData = filteredData.sort((a, b) => a.id - b.id);

  return (
    <Container>
      <div className='d-flex flex-row-reverse my-2 justify-content-between'>
        <div className='col-auto'>
          <Grid />
        </div>
        <div className='col-md-auto'>
          <h1 className='fs-3 mainTitle my-3'>{currentCategory}</h1>
          <hr className='w-100 thicker-hr'></hr>
        </div>
      </div>
      <div className='row'>
        <div className='col-8'>
          <input
            type='text'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='Search by Name or ID'
            className='form-control'
          />
        </div>
        <div className='col-4'>
          <select 
          value={filterBy} 
          onChange={(e) => handleFilter(e.target.value)} 
          className="form-select">
            <option key='all' value=''>
              All
            </option>
          {categories.map((category) => (
            <option key={category.id} value={category.value}>
              {category.value}
            </option>
          ))}
        </select>
        </div>
      </div>
      <div className='row justify-content-center'>
        {sortedData.map((art) => (
          <div
            key={art.id}
            className={`card ${isActive ? 'col-md-5 col-lg-4 my-3 mx-4' : 'col-md-3 col-lg-2 my-3 mx-4'}`}
          >
            <Link to={`/artwork/${art.id}`}>
              <div className='d-flex justify-content-center'>
                <Image src={art.imageUrl} className='images' fluid/>
              </div>
              <div className='card-body'>
                <h5 className='card-title'>{art.title}</h5>
                <p className='card-text'>£{art.price}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </Container>
  );
}
