import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { Button, Modal, Container, Form, Image } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { db } from "../../firebase-config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaPen, FaTrashAlt } from "react-icons/fa";
import ReviewForm from "./ReviewForm.js";
import CreateForm from "./CreateForm.js";
import VideoForm from "./VideoForm.js";

export default function AdminLogin() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedArtwork, setSelectedArtwork] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editedPrice, setEditedPrice] = useState("");
  const [editedAvailability, setEditedAvailability] = useState("");
  const [editedLink, setEditedLink] = useState("");

  const artCollectionRef = collection(db, "art");

  // Search Functionality
  const [searchTerm, setSearchTerm] = useState("");

  // Filter the data array based on the search term
  const filteredData = data.filter(
    (art) =>
      (art.id && art.id.toString().includes(searchTerm)) ||
      art.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort the filtered data array by the id field
  const sortedData = filteredData.sort((a, b) => a.id - b.id);

  const handleDelete = async (id, imageUrl) => {
    // Display a confirmation dialog
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmDelete) {
      return; // Do nothing if the user cancels
    }

    try {
      // Delete the document from Firestore
      const artworkQuery = query(collection(db, "art"), where("id", "==", id));
      const querySnapshot = await getDocs(artworkQuery);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      // Delete the image file from Firebase Storage
      // const imageQuery = query(collection(storageRef(storage, 'artwork')));
      // const imageSnapshot = await getDocs(imageQuery);
      // imageSnapshot.forEach((image) => {
      //   if (image.data().imageUrl === imageUrl) {
      //     const imageRef = storageRef(storage, `artwork/${image.data().imageName}`);
      //     deleteObject(imageRef);
      //   }
      // });

      toast.success("Painting and image successfully deleted");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOpenModal = (artwork) => {
    setSelectedArtwork(artwork);
    setEditedTitle(artwork.title);
    setEditedDescription(artwork.description);
    setEditedPrice(artwork.price);
    setEditedAvailability(artwork.availability);
    setEditedLink(artwork.link);
    setShowModal(true);
  };

  const handleUpdateArtwork = async () => {
    try {
      const artworkQuery = query(
        collection(db, "art"),
        where("id", "==", selectedArtwork.id)
      );
      const artworkSnapshot = await getDocs(artworkQuery);

      if (!artworkSnapshot.empty) {
        const artworkDoc = artworkSnapshot.docs[0];
        const artworkRef = doc(db, "art", artworkDoc.id);

        await updateDoc(artworkRef, {
          title: editedTitle,
          link: editedLink,
          description: editedDescription,
          price: editedPrice,
          availability: editedAvailability,
        });

        setShowModal(false);
        toast.success("Artwork successfully updated");
      } else {
        toast.error("Artwork not found");
      }
    } catch (error) {
      toast.error("Error updating artwork");
      console.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const q = query(artCollectionRef);
      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc) => doc.data());
      setData(fetchedData);
    };

    fetchData();
  }, []);

  const [isReviewFormVisible, setReviewFormVisible] = useState(false);
  const [isCreateFormVisible, setCreateFormVisible] = useState(false);
  const [isVideoFormVisible, setVideoFormVisible] = useState(false);

  const handleTabChange = (tab) => {
    // Toggle the visibility of the corresponding form
    if (tab === "review") {
      setReviewFormVisible(!isReviewFormVisible);
      // Hide the other form
      setCreateFormVisible(false);
    } else if (tab === "create") {
      setCreateFormVisible(!isCreateFormVisible);
      // Hide the other form
      setReviewFormVisible(false);
    } else if (tab === "video"){
      setVideoFormVisible(!isVideoFormVisible);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { uname, pass } = event.target.elements;

    try {
      const authQuery = query(
        collection(db, "auth"),
        where("username", "==", uname.value)
      );
      const authSnapshot = await getDocs(authQuery);

      if (!authSnapshot.empty) {
        authSnapshot.forEach((doc) => {
          const userData = doc.data();
          if (userData.password !== pass.value) {
            toast.error("Invalid username and/or password");
          } else {
            setIsSubmitted(true);
            toast.success("Succesfully Logged in");
          }
        });
      } else {
        toast.error("Invalid username and/or password");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderForm = (
    <Container>
      <Form className="w-50 mx-auto my-auto" onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="uname"
            placeholder="Enter User Name"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            name="pass"
            placeholder="Password"
          />
        </Form.Group>
        <Button
          variant="success"
          type="submit"
          className="mx-auto"
          style={{ backgroundColor: "#008080" }}
        >
          Log In
        </Button>
      </Form>
    </Container>
  );

  return (
    <div className="app">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <div className="login-form">
        {isSubmitted ? (
          <div className="mx-auto col-lg-8 col-md-12">
            <Container>
              <div>
                {/* Tab Navigation */}
                <div>
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      onClick={() => handleTabChange("review")}
                      className={`mx-auto btn btn-sm`}
                      style={{ backgroundColor: isReviewFormVisible ? "#008080" : "" }}
                    >
                      Add Review
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      onClick={() => handleTabChange("video")}
                      className={`mx-auto btn btn-sm`}
                      style={{ backgroundColor: isReviewFormVisible ? "#008080" : "" }}
                    >
                      Add Video
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      onClick={() => handleTabChange("create")}
                      className={`mx-auto btn btn-sm`}
                      style={{ backgroundColor: isVideoFormVisible ? "#008080" : "" }}
                    >
                      Add Painting
                    </a>
                  </li>
                </ul>
                </div>

                {/* Render the selected form */}
                {isReviewFormVisible && <ReviewForm />}
                {isCreateFormVisible && <CreateForm />}
                {isVideoFormVisible && <VideoForm/>}
              </div>
              <h1 className="fs-3 mainTitle my-3">Admin Page</h1>
              <hr className="w-25 thicker-hr"></hr>
              <div className="mt-5">
                <Form.Control
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by ID or Name"
                  className="mb-3 float-end"
                />

                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Price</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map((art) => (
                      <tr key={art.id}>
                        <td>{art.id}</td>
                        <td><Image src={art.imageUrl} className='img-fluid artwork-image mx-auto' /></td>
                        <td>{art.title}</td>
                        <td
                          dangerouslySetInnerHTML={{ __html: art.description }}
                        ></td>
                        <td>£{art.price}</td>
                        <td>
                          <Button
                            variant="success"
                            onClick={() => handleOpenModal(art)}
                            className="me-2"
                          >
                            <FaPen></FaPen>
                          </Button>
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => handleDelete(art.id)}
                            className="me-2"
                          >
                            <FaTrashAlt></FaTrashAlt>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Container>
          </div>
        ) : (
          renderForm
        )}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Artwork</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <ReactQuill
                required
                value={editedDescription}
                onChange={setEditedDescription}
                placeholder="Enter a Description"
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Etsy Link</Form.Label>
              <ReactQuill
                required
                value={editedLink}
                onChange={setEditedLink}
                placeholder="Enter an etsy link"
              />
            </Form.Group>
            <Form.Group controlId="formPrice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                required
                type="text"
                value={editedPrice}
                onChange={(e) => setEditedPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <div>
                <Form.Check
                  inline
                  type="radio"
                  label="Available"
                  name="availability"
                  id="available"
                  value="available"
                  checked={editedAvailability === "available"}
                  onChange={(event) =>
                    setEditedAvailability(event.target.value)
                  }
                />
                {/* <Form.Check
                    inline
                    type="radio"
                    label="Sold"
                    name="availability"
                    id="sold"
                    value="sold"
                    checked={editedAvailability === 'sold'}
                    onChange={(event) => setEditedAvailability(event.target.value)}
                  /> */}
                <Form.Check
                  inline
                  type="radio"
                  label="On Request"
                  name="availability"
                  id="onRequest"
                  value="onRequest"
                  checked={editedAvailability === "onRequest"}
                  onChange={(event) =>
                    setEditedAvailability(event.target.value)
                  }
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateArtwork}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
