import React, { useRef } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from '@emailjs/browser';
import { useParams } from "react-router-dom";

export default function ContactMe() {
  const { id } = useParams();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_4qlhz2n', 'template_4av7ida', form.current, 'kCF0he5QR660lutNk')
      .then(() => {
        toast.success("Email sent successfully! I'll respond to your email as soon as I can.");
        form.current.reset();
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <Container className="px-5 mt-3">
      <h1 className="fs-3 mainTitle my-3">Contact Me</h1>
      <hr className="w-25 thicker-hr"></hr>
      <p className="my-3">
        Please fill out this form to contact me about any purchases, requests or enquiries, and I’ll get back to you right away!
      </p>
      <Form className="w-100 my-auto" ref={form} onSubmit={sendEmail}>
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name="name" placeholder="Enter your name" required/>
        </Form.Group>
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Control type="hidden" name="item_ref" placeholder="Enter your item reference" defaultValue={id} required/>
        </Form.Group>
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" name="email" placeholder="Enter your email" required/>
        </Form.Group>
        <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Request Information</Form.Label>
          <Form.Control name="request" as="textarea" rows={3} placeholder="Enter any purchases, requests, enquiries or questions you may have here and I’ll get back to you right away" required/>
        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button variant="success" type="submit" style={{ backgroundColor: '#008080' }}>
            Submit
          </Button>
        </div>
      </Form>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </Container>
  );
}
